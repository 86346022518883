import React, { useEffect, useRef } from 'react'
import startCase from 'lodash/upperFirst'
import get from 'lodash/get'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'

import { useDebounce } from '../hooks/useDebounce'
import useBlogSearch from '../hooks/useBlogSearch'
import { COLORS } from '@borrowell/bw-styles'

import BlogCatalog from '../components/BlogCatalog'
import { Heading, Paragraph } from '../components/typography'
import { Input } from '../components/Input'
import { Button } from '../components/Button'
import { Dropdown } from '../components/Dropdown'
import Section from '../components/Section'
import { Link } from '../components/Link'

import { IBlogCatalog } from '../graphql/modules/blog'
import { IBlogCatalogSection } from '../graphql/sections/blogCatalogCategories'
import { ChangeEventWithMocks } from '../components/commonTypes'
import { useAriaLiveAnnouncement } from '../hooks/useAriaLiveAnnouncement'

interface ICategory {
  value: string
  label: string
}

interface ISectionProps {
  data: IBlogCatalogSection
  allBlogs: IBlogCatalog[]
}

interface IBlogSearchProps {
  searchTerm: string
  setSearchTerm: (term: string) => void
  currentCategory: ICategory
  screenReaderLabel: string
}

interface ICategoriesSectionProps {
  categories: ICategory[]
  selectedCategory: ICategory
  contentfulId: string | null
  typeName: string | null
  setSelectedCategory: (category: ICategory) => void
}

interface IAllCategoriesProps {
  categories: ICategory[]
  selected: ICategory
  contentfulId: string | null
  typeName: string | null
  handleChange: (value: string) => void
}

const heroSectionStyles = {
  margin: '20px auto 20px auto',
  padding: ['20px', '30px 0', '30px 0'],
  background: 'linear-gradient(109deg, #3421a7 -2%, #843ecf 103%)',
  color: 'white',
  maxWidth: '100vw',
}

const blogCatalogSectionStyles = {
  padding: ['0px 22px 60px 22px', '0 20px 70px 20px', '0 40px 45px 40px'],
}

const TextSection = styled.div`
  ${mq({
    maxWidth: '525px',
  })}
`

const SearchContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    fontFamily: 'Lato',
    maxWidth: ['none', '528px', '635px'],
  })}
`

const Label = styled.label`
  ${mq({
    textAlign: 'left',
    fontSize: ['12px', '16px', '16px'],
    lineHeight: '1.63',
    marginLeft: ['12px', '16px', '16px'],
    marginBottom: '-10px',
    color: COLORS.WHITE,
  })}
`

const DesktopAndTabletCategoryButtons = styled.div`
  ${mq({
    display: ['none', 'flex', 'flex'],
    width: '100%',
    flexDirection: ['row', 'row', 'column'],
    alignItems: ['', 'center', 'center'],
    justifyContent: ['', 'space-between', 'space-between'],
    padding: ['', '', '15px 12px'],
  })}
`

const MobileCategoryDropdown = styled.div`
  ${mq({
    display: ['flex', 'none', 'none'],
    width: '100%',
  })}
`

const Categories = styled.div`
  ${mq({
    display: 'flex',
    maxWidth: '1200px',
    flexDirection: ['column', 'row', 'row'],
    justifyContent: ['', 'center', 'center'],
    alignItems: ['', 'center', 'center'],
    padding: ['', '10px', '10px 0'],
    flexWrap: ['', 'wrap', 'wrap'],
  })}
`

const ButtonWrapper = styled.div`
  ${mq({
    display: 'flex',
    width: ['100%', 'auto', 'auto'],
    height: '48px',
    alignItems: ['', 'center', 'center'],
    margin: ['', '10px 6px', '10px 16px'],
  })}
`

const SearchMessage = styled.div`
  ${mq({
    fontFamily: 'Lato',
    fontSize: '22px',
    textAlign: 'center',
    color: COLORS.NEUTRAL.COOL['900'],
    margin: ['0 0 10px 0', '30px 0', '30px 0'],
  })}
`

const buttonStyles = {
  width: ['', '180px', '194px'],
}

const buttonActiveStyles = {
  ...buttonStyles,
  backgroundColor: COLORS.WHITE,
  color: COLORS.PURPLE['800'],
  border: 'none',
  ':hover': undefined,
}

const customHeaderStyles = {
  textAlign: 'center',
  color: 'white',
  lineHeight: '1.65',
}

const customParagraphStyles = {
  textAlign: 'center',
  color: 'white',
  margin: '0',
}

const archiveLinkStyles = {
  margin: '30px 0 0 0',
}
const archiveLinkTextStyles = {
  fontSize: ['12px', '14px', '14px'],
}

const ENABLED_SEARCH_PROPS = [
  {
    name: 'title',
    weight: 0.5,
  },
  {
    name: 'text.text',
    weight: 0.3,
  },
  {
    name: 'blogCategories',
    weight: 0.5,
  },
]

const AllCategories: React.FC<IAllCategoriesProps> = ({
  categories = [],
  selected,
  contentfulId,
  typeName,
  handleChange,
}) => {
  const { value: selectedValue } = selected
  return (
    <Categories>
      {categories.map(category => {
        const { label: text, value } = category

        const buttonStyle =
          selectedValue === value ? buttonActiveStyles : buttonStyles
        return (
          <ButtonWrapper key={value}>
            <Button
              name={`select_category_${text}`}
              onClick={() => handleChange(value)}
              theme="TERTIARY"
              styleOverrides={buttonStyle}
              contentfulId={contentfulId}
              typeName={typeName}
            >
              {text}
            </Button>
          </ButtonWrapper>
        )
      })}
    </Categories>
  )
}

const CategorySection: React.FC<ICategoriesSectionProps> = ({
  categories,
  selectedCategory,
  contentfulId,
  typeName,
  setSelectedCategory,
}) => {
  const handleChange = (value: string | number) => {
    setSelectedCategory(
      categories.find(category => category.value === value) || selectedCategory,
    )
  }
  return (
    <>
      <DesktopAndTabletCategoryButtons>
        <AllCategories
          categories={categories}
          selected={selectedCategory}
          handleChange={handleChange}
          contentfulId={contentfulId}
          typeName={typeName}
        />
      </DesktopAndTabletCategoryButtons>
      <MobileCategoryDropdown>
        <Dropdown
          id="Blog-Categories-Select"
          label="Blog categories"
          isVisibleLabel={false}
          options={categories}
          selectedOption={selectedCategory}
          handleSelection={({ value }) => handleChange(value)}
        />
      </MobileCategoryDropdown>
    </>
  )
}

const BlogSearch: React.FC<IBlogSearchProps> = ({
  searchTerm,
  setSearchTerm,
  currentCategory,
  screenReaderLabel,
}) => {
  const category = currentCategory.label
  return (
    <>
      <Label htmlFor="searchBar">Searching within {<b>{category}</b>}</Label>
      <Input
        id="search-field"
        onChange={(e: ChangeEventWithMocks) => setSearchTerm(e.target.value)}
        value={searchTerm}
        name="searchBar"
        label={screenReaderLabel}
        isLabelScreenReaderOnly
        clearInputButton
        isDarkTreatment
      />
    </>
  )
}

const useFilteredBlogs = (allContentfulBlogEdges: IBlogCatalog[]) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [categoryFilter, setCategoryFilter] = React.useState({
    value: '',
    label: 'All blogs',
  })

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const { filteredBlogs, hasMore, loadMore } = useBlogSearch(
    allContentfulBlogEdges,
    debouncedSearchTerm,
    {
      searchProps: ENABLED_SEARCH_PROPS,
      filterByCategory: categoryFilter.value,
      loadLimit: 6,
      progressiveLoad: true,
      initPage: 1,
    },
  )
  return {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    categoryFilter,
    setCategoryFilter,
    filteredBlogs,
    hasMore,
    loadMore,
  }
}

export const BlogCatalogWithCategoriesSection: React.FC<ISectionProps> = ({
  data,
  allBlogs,
}) => {
  const {
    title,
    text: { text },
    blogArchiveCopy,
    searchBarLabel,
    contentful_id,
    __typename,
  } = data

  const categories = React.useMemo(
    () => [
      { value: '', label: 'All Blogs' },
      ...data.categoryFilters.map(({ title: category }) => ({
        value: category,
        label: category,
      })),
    ],
    [data],
  )
  const {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    categoryFilter,
    setCategoryFilter,
    filteredBlogs,
    hasMore,
    loadMore,
  } = useFilteredBlogs(allBlogs)

  const { liveRegion, ariaAnnounce } = useAriaLiveAnnouncement({
    options: { delay: 500 },
  })

  const lastBlogListLength = useRef(0)
  useEffect(() => {
    if (
      filteredBlogs.length > lastBlogListLength.current &&
      lastBlogListLength.current !== 0
    ) {
      ariaAnnounce(
        `Loaded ${
          filteredBlogs.length - lastBlogListLength.current
        } more blogs above`,
      )
    }
    lastBlogListLength.current = filteredBlogs.length
  }, [ariaAnnounce, filteredBlogs])

  const categoryLabel = get(categoryFilter, 'label', '')
  const searchFilterString = `${categoryLabel} ${
    debouncedSearchTerm && `+ ${startCase(debouncedSearchTerm)}`
  }`

  return (
    <React.Fragment>
      <Section title={title} customStyles={heroSectionStyles}>
        <TextSection>
          <Heading styleOverrides={customHeaderStyles}>{title}</Heading>
          <Paragraph styleOverrides={customParagraphStyles}>{text}</Paragraph>
        </TextSection>
        <CategorySection
          categories={categories}
          selectedCategory={categoryFilter}
          setSelectedCategory={setCategoryFilter}
          contentfulId={contentful_id}
          typeName={__typename}
        />
        <SearchContainer>
          <BlogSearch
            currentCategory={categoryFilter}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            screenReaderLabel={searchBarLabel}
          />
        </SearchContainer>
      </Section>
      <Section title="Blog catalog" customStyles={blogCatalogSectionStyles}>
        <SearchMessage>
          Showing results for <b>{searchFilterString}</b>
        </SearchMessage>
        <BlogCatalog blogList={filteredBlogs} />
        {hasMore && (
          <Button
            name="Blog_load_more"
            onClick={loadMore}
            contentfulId={contentful_id}
            typeName={__typename}
          >
            Load More
          </Button>
        )}
        {liveRegion}
        <Link
          to="/blog/archive"
          textStyles={archiveLinkTextStyles}
          styleOverrides={archiveLinkStyles}
          contentfulId={contentful_id}
          typeName={__typename}
        >
          {blogArchiveCopy}
        </Link>
      </Section>
    </React.Fragment>
  )
}
