import React from 'react'
import Fuse from 'fuse.js'
import { IBlogCatalog } from '../graphql/modules/blog'

interface ISearchProp {
  weight: number
  name: string
}

interface IConfig {
  searchProps?: ISearchProp[]
  filterByCategory?: string
  loadLimit?: number
  progressiveLoad?: boolean
  initPage?: number
  filterByAuthor?: string
}

const useSearch = (list: IBlogCatalog[], searchProps: ISearchProp[] = []) => {
  return React.useMemo(
    () =>
      new Fuse(list, {
        keys: searchProps,
        shouldSort: true,
        threshold: 0.3,
        isCaseSensitive: false,
        ignoreLocation: true,
      }),
    [list, searchProps],
  )
}

const useBlogSearch = (
  allBlogs: IBlogCatalog[],
  searchQuery = '',
  {
    searchProps,
    filterByCategory,
    filterByAuthor,
    loadLimit = 6,
    progressiveLoad = true,
    initPage = 1,
  }: IConfig = {},
): {
  filteredBlogs: IBlogCatalog[]
  hasMore: boolean
  loadMore: () => void
} => {
  const searchEngine = useSearch(allBlogs, searchProps)
  const [searchResults, setSearchResults] = React.useState<IBlogCatalog[]>([])
  const [filteredBlogs, setFilteredBlogs] = React.useState<IBlogCatalog[]>([])
  const [pageNumber, setPageNumber] = React.useState(initPage)

  //Search and category section
  React.useEffect(() => {
    let results = allBlogs

    // Filtering by search
    if (searchQuery && searchProps) {
      results = searchEngine.search(searchQuery).map(result => result.item)
    }

    // Filtering by category
    if (filterByCategory) {
      results = results.filter(result =>
        result.blogCategories?.some(({ title }) => title === filterByCategory),
      )
    }

    // Filtering by author
    if (filterByAuthor) {
      results = results.filter(
        result => result.authorBio?.title === filterByAuthor,
      )
    }

    setSearchResults(results)
    setPageNumber(1)
  }, [
    searchQuery,
    allBlogs,
    searchProps,
    searchEngine,
    filterByCategory,
    filterByAuthor,
  ])

  //Pagination section
  React.useEffect(() => {
    const startIndex = progressiveLoad ? 0 : (pageNumber - 1) * loadLimit
    setFilteredBlogs(searchResults.slice(startIndex, pageNumber * loadLimit))
  }, [pageNumber, searchResults, progressiveLoad, loadLimit])

  return {
    filteredBlogs,
    hasMore: searchResults.length > pageNumber * loadLimit,
    loadMore: () => setPageNumber(page => page + 1),
  }
}

export default useBlogSearch
