import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import BlogPost from './BlogPost'

import { IBlogInfo } from '../graphql/modules/blog'

const BlogListContainer = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: ['center', 'center', 'space-evenly'],
  })}
`

export const BlogCatalog: React.FC<{ blogList: IBlogInfo[] }> = React.memo(
  ({ blogList = [] }) => {
    return (
      <BlogListContainer>
        {blogList.map(blog => (
          <BlogPost key={blog.title} blog={blog} />
        ))}
      </BlogListContainer>
    )
  },
)

export default BlogCatalog
