import React, { useEffect, useRef, useState } from 'react'
import startCase from 'lodash/upperFirst'
import get from 'lodash/get'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'

import { useDebounce } from '../hooks/useDebounce'
import useBlogSearch from '../hooks/useBlogSearch'
import { COLORS } from '@borrowell/bw-styles'

import BlogCatalog from '../components/BlogCatalog'
import { Heading } from '../components/typography'
import { Button } from '../components/Button'
import Section from '../components/Section'
import { Link } from '../components/Link'
import { BlogAuthor } from '../components/BlogAuthor'

import { IBlogCatalog } from '../graphql/modules/blog'
import { IBlogCatalogSection } from '../graphql/sections/blogCatalogCategories'
import { useAriaLiveAnnouncement } from '../hooks/useAriaLiveAnnouncement'
import { IAuthorBio } from '../graphql/modules/author'
import { IImage } from '../graphql/modules/image'
import { graphql, useStaticQuery } from 'gatsby'

interface ISectionProps {
  data: IBlogCatalogSection
  allBlogs: IBlogCatalog[]
}

interface ISocialImageData {
  externalLinkImage: IImage
  facebookImage: IImage
  twitterImage: IImage
  linkedinImage: IImage
}

type SocialImageKeys =
  | 'externalLinkImage'
  | 'twitterImage'
  | 'linkedinImage'
  | 'facebookImage'

const blogCatalogSectionStyles = {
  padding: ['30px 22px 60px 22px', '0 20px 70px 20px', '0 40px 45px 40px'],
}

const blogAuthorHeaderStyles = {
  padding: [
    '10px 21px 10px 21px',
    '10px 35px 10px 35px',
    '10px 60px 10px 60px',
  ],
}

const BackToBlogLink = styled.div`
  ${mq({
    width: '100%',
    marginBottom: 40,
  })}
`

const backToBlogStyles = { textAlign: 'left', width: 'auto' }

const blogAuthorStyles = {
  width: 620,
  maxWidth: '100%',
  padding: '40px 20px',
  border: 'none',
}

const ENABLED_SEARCH_PROPS = [
  {
    name: 'title',
    weight: 0.5,
  },
  {
    name: 'text.text',
    weight: 0.3,
  },
  {
    name: 'blogCategories',
    weight: 0.5,
  },
]

const useFilteredBlogs = (
  allContentfulBlogEdges: IBlogCatalog[],
  authorBio: IAuthorBio,
) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [categoryFilter, setCategoryFilter] = React.useState({
    value: '',
    label: 'All blogs',
  })

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const { filteredBlogs, hasMore, loadMore } = useBlogSearch(
    allContentfulBlogEdges,
    debouncedSearchTerm,
    {
      searchProps: ENABLED_SEARCH_PROPS,
      filterByCategory: categoryFilter.value,
      filterByAuthor: authorBio?.title ?? '',
      loadLimit: 6,
      progressiveLoad: true,
      initPage: 1,
    },
  )
  return {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    categoryFilter,
    setCategoryFilter,
    filteredBlogs,
    hasMore,
    loadMore,
  }
}

export const BlogCatalogWithAuthorSection: React.FC<ISectionProps> = ({
  data,
  allBlogs,
}) => {
  const { title, authorBio, contentful_id, __typename } = data

  const { filteredBlogs, hasMore, loadMore } = useFilteredBlogs(
    allBlogs,
    authorBio,
  )

  const { liveRegion, ariaAnnounce } = useAriaLiveAnnouncement({
    options: { delay: 500 },
  })

  const lastBlogListLength = useRef(0)
  useEffect(() => {
    if (
      filteredBlogs.length > lastBlogListLength.current &&
      lastBlogListLength.current !== 0
    ) {
      ariaAnnounce(
        `Loaded ${
          filteredBlogs.length - lastBlogListLength.current
        } more blogs above`,
      )
    }
    lastBlogListLength.current = filteredBlogs.length
  }, [ariaAnnounce, filteredBlogs])

  const backLinkLabel = '< Back to All Blogs'

  const {
    externalLinkImage,
    facebookImage,
    twitterImage,
    linkedinImage,
  }: ISocialImageData = useStaticQuery(graphql`
    query AuthorPageSocialImageQuery {
      externalLinkImage: contentfulImage(
        contentful_id: { eq: "2SvLbD8vGU9ulz8eFjBkWS" }
      ) {
        ...ImageFragment
      }
      facebookImage: contentfulImage(
        contentful_id: { eq: "7hWJuYjkvU5L07fqnYAX3p" }
      ) {
        ...ImageFragment
      }
      twitterImage: contentfulImage(
        contentful_id: { eq: "31cdgcaudJ4j1dJvB4OfWH" }
      ) {
        ...ImageFragment
      }
      linkedinImage: contentfulImage(
        contentful_id: { eq: "1BGO5qyHg7fhbvPkH3SPLs" }
      ) {
        ...ImageFragment
      }
    }
  `)

  const socialImages: Record<SocialImageKeys, IImage> = {
    externalLinkImage: externalLinkImage,
    facebookImage: facebookImage,
    twitterImage: twitterImage,
    linkedinImage: linkedinImage,
  }

  return (
    <React.Fragment>
      <Section title={title} customStyles={blogAuthorHeaderStyles}>
        <BackToBlogLink>
          <Link
            to="/blog"
            styleOverrides={backToBlogStyles}
            contentfulId={contentful_id}
            typeName={__typename}
          >
            {backLinkLabel}
          </Link>
        </BackToBlogLink>
        <Heading>{title}</Heading>
        <BlogAuthor
          data={authorBio}
          isAuthorPage={true}
          styleOverrides={blogAuthorStyles}
          socialImages={socialImages}
        />
      </Section>
      <Section title="Blog catalog" customStyles={blogCatalogSectionStyles}>
        <BlogCatalog blogList={filteredBlogs} />
        {hasMore && (
          <Button
            name="Blog_load_more"
            onClick={loadMore}
            contentfulId={contentful_id}
            typeName={__typename}
          >
            Load More
          </Button>
        )}
        {liveRegion}
      </Section>
    </React.Fragment>
  )
}
