import { graphql } from 'gatsby'
import React, { useEffect, useMemo } from 'react'

import { SEO } from '../components/SEO'
import { TypeNames } from '../constants/enums'
import { usePageContext } from '../context/PageContextProvider'
import { IBlogCatalog } from '../graphql/modules/blog'
import { IBlogCatalogSection } from '../graphql/sections/blogCatalogCategories'
import { IMetadata } from '../graphql/sections/metadata'
import { Sections } from '../graphql/sections/types'
import deriveMetadata from '../helpers/deriveMetadata'
import { useSendPageViewedEvent } from '../hooks/analytics/useSendPageViewedEvent'
import { SectionContainer } from '../styles/containerStyles'
import { BlogCatalogWithAuthorSection } from './BlogCatalogWithAuthorSection'
import { BlogCatalogWithCategoriesSection } from './BlogCatalogWithCategoriesSection'
import FooterSection from './FooterSection'
import StickyCTASection from './StickyCTASection'

type Reduces<T, U extends T> = U

interface IProps {
  data: {
    contentfulPage: {
      sections: Reduces<Sections, IMetadata | IBlogCatalogSection>[]
      contentful_id: string
    }
    allContentfulBlog: {
      edges: {
        node: IBlogCatalog
      }[]
    }
  }
}

const PageAdapterBlog: React.FC<IProps> = ({
  data: {
    contentfulPage: { sections: sectionsList, contentful_id: pageContentfulId },
    allContentfulBlog: { edges: allContentfulBlogEdges },
  },
}) => {
  const { pageContext, setPageContext } = usePageContext()
  const sendPageViewedEvent = useSendPageViewedEvent()

  useEffect(() => {
    setPageContext({
      pageContentfulId,
      // Experiments are not supported yet for blog pages.
      experiment: null,
      treatmentRequested: null,
      treatmentSelected: null,
      pagePath: window.location.pathname,
      pageUrl: window.location.href,
    })
  }, [pageContentfulId])

  useEffect(() => {
    if (pageContentfulId === pageContext.pageContentfulId) {
      sendPageViewedEvent(pageContentfulId)
    }
  }, [pageContentfulId, pageContext.pageContentfulId, sendPageViewedEvent])

  const allBlogs = React.useMemo(() => {
    return allContentfulBlogEdges.map(({ node }) => node)
  }, [allContentfulBlogEdges])

  const hasMetadata = sectionsList.some(section => section.__typename === TypeNames.CONTENTFUL_METADATA)

  if (!hasMetadata) {
    sectionsList.push(deriveMetadata(sectionsList))
  }

  const sections = useMemo(
    () =>
      sectionsList &&
      sectionsList.map(section => {
        switch (section.__typename) {
          case TypeNames.CONTENTFUL_BLOG_CATALOG_WITH_CATEGORIES: {
            return section.authorBio ? (
              <BlogCatalogWithAuthorSection key={section.contentful_id} data={section} allBlogs={allBlogs} />
            ) : (
              <BlogCatalogWithCategoriesSection key={section.contentful_id} data={section} allBlogs={allBlogs} />
            )
          }
          case TypeNames.CONTENTFUL_METADATA: {
            return <SEO key={section.contentful_id} data={section} />
          }
          default: {
            return null
          }
        }
      }),
    [sectionsList, allBlogs],
  )

  return (
    <SectionContainer data-entry-id={pageContentfulId}>
      {sections}
      <StickyCTASection />
      <FooterSection />
    </SectionContainer>
  )
}

export default PageAdapterBlog

export const pageQuery = graphql`
  query ContentfulBlogPage($pageId: String!) {
    contentfulPage(contentful_id: { eq: $pageId }) {
      contentful_id
      sections {
        __typename
        ...MetadataFragment
        ...BlogCatalogWithCategoriesFragment
      }
    }
    allContentfulBlog(sort: { fields: date, order: DESC }) {
      edges {
        node {
          ...BlogCatalogFragment
        }
      }
    }
  }
`
