import React from 'react'
import debounce from 'lodash/debounce'

interface IDebounceSettings {
  leading?: boolean
  maxWait?: number
  trailing?: boolean
}

function useDebouncedCallback<T extends unknown>(
  callback: (value: T) => void,
  delay: number,
  options?: IDebounceSettings
) {
  return React.useCallback(debounce(callback, delay, options), [
    callback,
    delay,
    options,
  ])
}

export function useDebounce<T extends unknown>(
  value: T,
  delay: number,
  options?: IDebounceSettings
): T {
  const previousValue = React.useRef(value)
  const [current, setCurrent] = React.useState(value)
  const debouncedCallback = useDebouncedCallback<T>(
    value => setCurrent(value),
    delay,
    options
  )
  React.useEffect(() => {
    // does trigger the debounce timer initially
    if (value !== previousValue.current) {
      debouncedCallback(value)
      previousValue.current = value
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel
    }
  }, [value, debouncedCallback])

  return current
}
